
.fff {
  font-family: fff;
  line-height: 1.086;
  font-style: normal;
  font-weight: normal;
  visibility: visible;
}

body {
  /* margin-top: 8vh; */
  /* margin-bottom: 55px; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
@media only screen and (max-width: 425px) {
  body {
    background-color: white;
  }
}
/* <<<<<<< HEAD ======= */
@media only screen and (max-width: 425px) {
  body {
    background-color: white;
  }
}
/* >>>>>>>d2c445eb656f9f9030b60baee7afa48ddd920bc1 */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media only screen and (min-width: 1024px) {
  body {
    margin-top: auto;
    overflow-x: hidden !important;
  }
}
/* @media only screen and (max-width: 768px) {
  body {
    margin-top: 59vh;
  }
} */
@media (max-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  /* body {
    margin-top: 8vh;
  } */
}
@media (max-width: 375px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  /* body {
    margin-top: 7vh;
  } */
}
@media (min-width: 768px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  body {
    /* margin-top: 11vh; */
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  body {
    /* margin-top: 6%; */
  }
}
@media (min-width: 1900px) {
  /* hi-res laptops and desktops */
}
/* .desktop-only {
  display: none !important;
}
@media only screen and (min-width: 481px) {
  .desktop-only {
    display: block !important;
    z-index: 10000;
  }
} */
.text-align-end{
  text-align: end;
}